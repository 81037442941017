(function ($) {
  Drupal.behaviors.filterableShadesFilterSetV1 = {
    attached: false,
    nodes: [],
    isMobile: false,
    isMedium: false,
    pageDirection: 'ltr',
    selector: {
      container: '.js-filterable-find-shades-block',
      filters: '.js-filterable-shades-filter-set',
      grid: '.js-filterable-shades-grids-formatter',
      item: '.js-filterable-shades-grid-item',
      filtered: 'js-filterable-shades-grids--filtered',
      unfiltered: 'js-filterable-shades-grids--unfiltered',
      filterBtn: '.js-filterable-shades-filter-set-key-button',
      filterTab: '.js-filterable-shades-filter-set-key-tab',
      filterSection: '.js-filterable-shades-filter-set__section',
      filterSets: '.js-filterable-shades-filter-set-key__filters',
      filterSetsWrapper: '.js-filterable-shades-filter-set-key__filters-wrapper',
      filterButtonMobile: '.js-filterable-shades-filter-set__label',
      filterSetsBody: '.js-filterable-shades-filter-set__body',
      filterButtonSpanMobile: '.js-filterable-shades-filter-set__label span',
      filterArrow: '.js-filterable-shades-filter-set__filter-arrow',
      resetBtn: '.js-reset-link',
      productCount: '.js-filterable-shades-filter-set__count',
      productCountNumber: '.js-filterable-shades-filter-set__count--number'
    },
    cssClass: {
      filtered: 'filterable-shades-grids--filtered',
      unfiltered: 'filterable-shades-grids--unfiltered',
      hasModelImages: 'js-mpp-has-model-images'
    },
    hasFilterDefault: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      var $filterBlocks = $(self.selector.filters, context);

      self.setup($filterBlocks);
    },
    setup: function ($filterBlocks) {
      var self = this;

      self.attached = true;
      self.isMobile = self.checkIfMobile();

      $filterBlocks.each(function () {
        var $this = $(this);

        self.initDOM($this);
        self.buildFilters($this);
        self.hasFilterDefault = self.defaultFilter($this);
        self.setEvents($this);
      });
    },
    checkIfMobile: function () {
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();

      if (!!bp && !!bps) {
        return parseInt(bp.width, 10) <= parseInt(bps.medium, 10);
      }

      return false;
    },
    initDOM: function ($this) {
      var self = this;

      $this.nodes = {};
      $this.nodes.$container = $this.closest(self.selector.container);
      $this.nodes.$grid = $this.nodes.$container.find(self.selector.grid);
      $this.nodes.$items = $this.nodes.$grid.find(self.selector.item);
      $this.nodes.$filterSections = $this.find(self.selector.filterSection);
      $this.nodes.$filterSets = $this.find(self.selector.filterSets);
      $this.nodes.$filterSetsWrapper = $this.find(self.selector.filterSetsWrapper);
      $this.nodes.$filterSetsBody = $this.find(self.selector.filterSetsBody);
      $this.nodes.$filterBtns = $this.find(self.selector.filterBtn);
      $this.nodes.$filterTabs = $this.find(self.selector.filterTab);
      $this.nodes.$filterBtnsMobile = $this.find(self.selector.filterButtonMobile);
      $this.nodes.$filterBtnSpanMobile = $this.find(self.selector.filterBtnSpanMobile);
      $this.nodes.$filterArrow = $this.find(self.selector.filterArrow);
      $this.nodes.$resetBtn = $this.find(self.selector.resetBtn);
      $this.nodes.$productCount = $this.find(self.selector.productCount);
      $this.nodes.$productCountNumber = $this.find(self.selector.productCountNumber);
      $this.nodes.$filterSet = $this;
    },
    defaultFilter: function ($that) {
      var self = this;
      var defaultFilter = $that.nodes.$filterSets.data('defaultFilter');
      var $currentFilter = $();

      if (!!defaultFilter) {
        $currentFilter = $that.nodes.$filterBtns.filter(
          '[data-filter=".filterable-shades-grid__item--filter-' + defaultFilter + '"]'
        );
        if ($currentFilter.length > 0) {
          $currentFilter.addClass('active');
          $that.nodes.$grid.attr('data-default-filter-value', defaultFilter);
          self.toogleFilterOtherSection($that, defaultFilter);
        }
        self.updateAvailableFilters($that);

        return true;
      }
      return false;
    },
    processTags: function (tagsData) {
      if (typeof tagsData !== 'string') {
        return [];
      }
      // Cleaning specific char's since we have accent values comming from .NET that need to stay
      var newData = tagsData.replace(/['`~:'".\\\/\s+]/gi, '').toLowerCase();
      // Loop through our tags, split them by comma, then trim the whitespace
      var tags = _.map(newData.split(','), $.trim);

      return tags;
    },
    hasFiltersTags: function ($this, properTags, filterType, $filterSets) {
      var hasFilters = false;
      var filterClass = '';

      // Loop through our tags, and add the class to this shades item
      _.each(properTags, function (index) {
        filterClass = 'filterable-shades-grid__item--filter-' + index;
        $this.addClass(filterClass);
        hasFilters = true;
        if ($filterSets.length > 0) {
          // Show the filter when called from product
          $filterSets
            .find('[data-filter=".' + filterClass + '"]')
            .data('filterType', filterType)
            .attr('data-filter-type', filterType)
            .removeClass('hidden');
        }
      });

      return hasFilters;
    },
    buildFilters: function ($that) {
      var self = this;
      var hasFilters = false;

      // Loop through all items
      $that.nodes.$items.once().each(function () {
        var $item = $(this);
        var hasSkuFilters = false;
        var itemAttribute = $item.data('attributes');
        var data = '';
        var properTags = '';

        if (!itemAttribute.tags && !itemAttribute.skuTags) {
          return;
        }

        if (itemAttribute.tags) {
          data = itemAttribute.tags;
          properTags = self.processTags(data);
          hasFilters = self.hasFiltersTags(
            $item,
            properTags,
            'product-filter',
            $that.nodes.$filterSets
          );
        }

        if (itemAttribute.skuTags) {
          data = itemAttribute.skuTags;
          properTags = self.processTags(data);
          hasSkuFilters = self.hasFiltersTags($item, properTags, 'sku-filter', $that.nodes.$filterSets);
          if (!hasFilters && hasSkuFilters) {
            hasFilters = true;
          }
        }
      });

      if (hasFilters) {
        self.displayTotalGrids($that);

        $that.removeClass('hidden');
        self.buildTabs($that);
      }
    },
    displayTotalGrids: function($that) {
      var self = this;
      var filterType = $that.nodes.$grid.data('filterTypeFindShade');
      var $allBtn = $that.nodes.$filterBtns.filter('[data-filter-value="all"]');

      if (filterType === 'filter_prd_others' && $that.nodes.$productCountNumber.length > 0) {
        $that.nodes.$productCountNumber.html($that.nodes.$items.length);
        $that.nodes.$productCount.removeClass('hidden');
      }
      if (filterType === 'filter_prd_foundation') {
        if ($allBtn.length > 0) {
          $allBtn.removeClass('hidden');
        }
        self.updateAllCountText($that);
      }
    },
    updateAllCountText: function ($that) {
      $that.nodes.$filterBtns.each(function () {
        var $this = $(this);
        var filterValue = $this.data('filterValue');
        var $countText = $('.js-filter-count-text', $this);
        var $filterItems = $('.filterable-shades-grid__item--filter-'+ filterValue, $that.nodes.$container);
        var countValue = filterValue ==='all' ? $that.nodes.$items.length : $filterItems.length;
        if (countValue > 0) {
          $countText.html('(' + countValue + ')');
        }
      });
    },
    buildTabs: function ($that) {
      $that.nodes.$filterTabs.removeClass('hidden');
      $defaultTab = $that.nodes.$filterTabs.filter('[data-tab-value="face"]');
      $defaultTab.addClass('tab-selected');
    },
    animateFilterBtns: function ($that) {
      var lastScrollLeft = 0;
      $that.nodes.$filterSets.addClass('gradient-last');
      $that.nodes.$filterSets.on('scroll',
        _.throttle(function () {
          var $menuBtns = $that.nodes.$filterSets;
          var documentScrollLeft = $menuBtns.scrollLeft();
          if (lastScrollLeft < documentScrollLeft) {
            $menuBtns.addClass('gradient-first').removeClass('gradient-last');
          } else  if (lastScrollLeft > documentScrollLeft) {
            $menuBtns.addClass('gradient-last').removeClass('gradient-first');
          }
          lastScrollLeft = documentScrollLeft;
        }, 500)
      );
    },
    simulateFilterCount: function ($that, simulateSelector) {
      var activeFilterSelector = simulateSelector;

      return parseInt($that.nodes.$items.filter(activeFilterSelector).length);
    },
    toggleFilterElement: function (count, $el) {
      if (count === 0) {
        $el.addClass('disabled').attr('aria-disabled', 'true');
      } else {
        $el.removeClass('disabled').attr('aria-disabled', 'false');
      }
    },
    updateAvailableFilters: function ($that) {
      var self = this;
      var $filterTriggeredObj = $();

      $that.nodes.$filterBtns.filter(':not(.active)').each(function () {
        var $this = $(this);
        var $dataFilter = $(this).data('filter');
        var simulateCount = self.simulateFilterCount($that, $dataFilter);

        self.toggleFilterElement(simulateCount, $this, $that);
      });

      $filterTriggeredObj = $that.nodes.$filterBtns.filter('.filter-in-progress:not(.active)');

      if ($filterTriggeredObj.length > 0) {
        $filterTriggeredObj.parent().find($that.nodes.$filterBtns).addClass('activeSibling');
        $that.nodes.$filterBtns.filter('.active:not(.activeSibling)').each(function () {
          var $this = $(this);
          var $dataFilter = $(this).data('filter');
          var simulateCount = self.simulateFilterCount($that, $dataFilter);

          self.toggleFilterElement(simulateCount, $this, $that);
        });
        $filterTriggeredObj.removeClass('filter-in-progress');
        $that.nodes.$filterBtns.removeClass('activeSibling');
      }
    },
    getActiveFilters: function ($that) {
      var $activeBtn = $that.nodes.$filterBtns.filter('.active');
      var filterSring = $activeBtn.data('filter');
      var filterValue = $activeBtn.data('filterValue');
      if (filterValue === "all") {
        filterSring = filterValue;
      }

      return filterSring;
    },
    filter: function ($that) {
      var self = this;
      var filterActive = self.getActiveFilters($that);
      var filterString = filterActive || 'all';
      var onDefault = filterString === 'all' ? true : false;

      if ($that.nodes.$grid.hasClass('mixitup-initialized')) {
        self.filterMixItUp($that, filterString, onDefault);
      }
    },
    filterMixItUp: function ($that, filterString, onDefault) {
      var self = this;

      self.setBusyState($that, true);
      if (filterString !== self.selector.defaultFilter) {
        $that.nodes.$grid.trigger('stateChanged', onDefault);
        $that.nodes.$grid.mixItUp('filter', filterString);
      }
    },
    setBusyState: function ($that, isBusy) {
      if (isBusy) {
        $that.nodes.$filterBtns.addClass('busy');
        $that.nodes.$resetBtn.addClass('busy');
      } else {
        $that.nodes.$filterBtns.removeClass('busy');
        $that.nodes.$resetBtn.removeClass('busy');
      }
    },
    setEvents: function ($that) {
      var self = this;

      // Collect each filter
      $that.nodes.$filterBtns.each(function () {
        var $this = $(this);

        $this
          .once()
          .on('click', function (event) {
            if ($this.hasClass('busy')) {
              return;
            }
            event.preventDefault();
            self.filterTriggered($this, $that);
          }).on('keydown', function (event) {
            if (event.key === "Enter") {
              if ($(this).hasClass('busy')) {
                return;
              }
              self.filterTriggered($this, $that);
            }
          });
      });

      $that.nodes.$filterTabs.each(function () {
        var $this = $(this);

        $this
          .once()
          .on('click', function (event) {
            if ($this.hasClass('busy')) {
              return;
            }
            event.preventDefault();
            self.showTabImage($this, $that);
          }).on('keydown', function (event) {
            if (event.key === "Enter") {
              if ($this.hasClass('busy')) {
                return;
              }
              self.showTabImage($this, $that);
            }
          });
      });
      $that.nodes.$grid.on('mixEnd', function () {
        self.toggleFilterButtons($that);
        self.setBusyState($that, false);
      });
    },
    toggleFilterButtons: function ($that) {
      var self = this;
      var filterActive = $that.nodes.$filterBtns.filter('.active').length || false;

      if (filterActive) {
        $that.nodes.$grid.addClass(self.selector.filtered);
        $that.nodes.$grid.addClass(self.cssClass.filtered);
        $that.nodes.$grid.removeClass(self.cssClass.unfiltered);
        $that.nodes.$filterSet.addClass('filtered');
      } else {
        $that.nodes.$filterSet.removeClass('filtered');
      }
    },
    toogleFilterOtherSection: function ($that, filterOption) {
      var $allgridImages = $that.nodes.$items.find('.js-filterable-shades-grid-item__image');
      var filterType = $that.nodes.$grid.data('filterTypeFindShade');

      if (filterType === 'filter_prd_others') {
        $allgridImages.removeClass('active');
        $that.nodes.$items.find('.js-filterable-shades-grid-item__image-' + filterOption).addClass('active');
      }
    },
    showTabImage: function ($this, $that) {
      var activeTabValue = $this.data('tabValue');
      var filterType = $that.nodes.$grid.data('filterTypeFindShade');
      if (filterType === 'filter_prd_foundation') {
        $that.nodes.$items.find('.js-filterable-shades-grid-item__image').removeClass('active');
        $that.nodes.$items.find('.js-filterable-shades-grid-item__image--'+ activeTabValue).addClass('active');
        $that.nodes.$grid.attr('data-tab-grid-layout', activeTabValue);
        $that.nodes.$filterTabs.removeClass('tab-selected').attr('aria-checked', 'false');
        $this.addClass('tab-selected').attr('aria-checked', 'true');
      }
    },

    filterTriggered: function ($this, $that) {
      var self = this;
      var filterValue = '';
      
      if ($this.hasClass('disabled') || $this.hasClass('active')) {
        return;
      }

      $that.nodes.$items.removeClass('items-filtered items-filtered-hidden');
      $that.nodes.$filterBtns.removeClass('active').attr('aria-checked', 'false');
      $this.addClass('active').attr('aria-checked', 'true');
      filterValue = $this.data('filterValue');
      $that.nodes.$container.find('.js-filterable-shades-grids-view-more').addClass('viewmore-disabled');

      self.toogleFilterOtherSection($that, filterValue);

      self.filter($that);
      self.toggleFilterButtons($that);
    }
  };
})(jQuery);
